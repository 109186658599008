<template>
  <div class="container">
    <Header></Header>
	<div class="contact">
		<section class="socials">
			<div class="social__content">
				<h2 class="heading">Contact Me</h2>
				<!-- <hr class="scotch-rule"> -->
				<ul class="social">
					<li>
						<a target="_blank" rel="noopener" href="https://www.github.com/callezenwaka" title="Github">
							<!-- <?xml version="1.0" encoding="iso-8859-1"?> -->
							<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
							<svg class="social__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
								<g>
									<path d="M255.968,5.329C114.624,5.329,0,120.401,0,262.353c0,113.536,73.344,209.856,175.104,243.872
										c12.8,2.368,17.472-5.568,17.472-12.384c0-6.112-0.224-22.272-0.352-43.712c-71.2,15.52-86.24-34.464-86.24-34.464
										c-11.616-29.696-28.416-37.6-28.416-37.6c-23.264-15.936,1.728-15.616,1.728-15.616c25.696,1.824,39.2,26.496,39.2,26.496
										c22.848,39.264,59.936,27.936,74.528,21.344c2.304-16.608,8.928-27.936,16.256-34.368
										c-56.832-6.496-116.608-28.544-116.608-127.008c0-28.064,9.984-51.008,26.368-68.992c-2.656-6.496-11.424-32.64,2.496-68
										c0,0,21.504-6.912,70.4,26.336c20.416-5.696,42.304-8.544,64.096-8.64c21.728,0.128,43.648,2.944,64.096,8.672
										c48.864-33.248,70.336-26.336,70.336-26.336c13.952,35.392,5.184,61.504,2.56,68c16.416,17.984,26.304,40.928,26.304,68.992
										c0,98.72-59.84,120.448-116.864,126.816c9.184,7.936,17.376,23.616,17.376,47.584c0,34.368-0.32,62.08-0.32,70.496
										c0,6.88,4.608,14.88,17.6,12.352C438.72,472.145,512,375.857,512,262.353C512,120.401,397.376,5.329,255.968,5.329z"/>
								</g>
							</svg>
						</a>
					</li>
					<li>
						<a target="_blank" rel="noopener" href="https://www.linkedin.com/in/callezenwaka" title="Linkedin">
							<!-- <?xml version="1.0" encoding="iso-8859-1"?> -->
							<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
							<svg class="social__icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								viewBox="0 0 382 382" style="enable-background:new 0 0 382 382;" xml:space="preserve">
								<g>
									<path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
									C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
									H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
									c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
									s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
									c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
									c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
									c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
									L341.91,330.654L341.91,330.654z"/>
								</g>
							</svg>
						</a>
					</li>
					<li>
						<a target="_blank" rel="noopener" href="https://www.twitter.com/callezenwaka" title="Twitter">
							<!-- <?xml version="1.0" encoding="iso-8859-1"?> -->
							<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
							<svg class="social__icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								viewBox="0 0 410.155 410.155" style="enable-background:new 0 0 410.155 410.155;" xml:space="preserve">
								<g>
									<path d="M403.632,74.18c-9.113,4.041-18.573,7.229-28.28,9.537c10.696-10.164,18.738-22.877,23.275-37.067
									l0,0c1.295-4.051-3.105-7.554-6.763-5.385l0,0c-13.504,8.01-28.05,14.019-43.235,17.862c-0.881,0.223-1.79,0.336-2.702,0.336
									c-2.766,0-5.455-1.027-7.57-2.891c-16.156-14.239-36.935-22.081-58.508-22.081c-9.335,0-18.76,1.455-28.014,4.325
									c-28.672,8.893-50.795,32.544-57.736,61.724c-2.604,10.945-3.309,21.9-2.097,32.56c0.139,1.225-0.44,2.08-0.797,2.481
									c-0.627,0.703-1.516,1.106-2.439,1.106c-0.103,0-0.209-0.005-0.314-0.015c-62.762-5.831-119.358-36.068-159.363-85.14l0,0
									c-2.04-2.503-5.952-2.196-7.578,0.593l0,0C13.677,65.565,9.537,80.937,9.537,96.579c0,23.972,9.631,46.563,26.36,63.032
									c-7.035-1.668-13.844-4.295-20.169-7.808l0,0c-3.06-1.7-6.825,0.485-6.868,3.985l0,0c-0.438,35.612,20.412,67.3,51.646,81.569
									c-0.629,0.015-1.258,0.022-1.888,0.022c-4.951,0-9.964-0.478-14.898-1.421l0,0c-3.446-0.658-6.341,2.611-5.271,5.952l0,0
									c10.138,31.651,37.39,54.981,70.002,60.278c-27.066,18.169-58.585,27.753-91.39,27.753l-10.227-0.006
									c-3.151,0-5.816,2.054-6.619,5.106c-0.791,3.006,0.666,6.177,3.353,7.74c36.966,21.513,79.131,32.883,121.955,32.883
									c37.485,0,72.549-7.439,104.219-22.109c29.033-13.449,54.689-32.674,76.255-57.141c20.09-22.792,35.8-49.103,46.692-78.201
									c10.383-27.737,15.871-57.333,15.871-85.589v-1.346c-0.001-4.537,2.051-8.806,5.631-11.712c13.585-11.03,25.415-24.014,35.16-38.591
									l0,0C411.924,77.126,407.866,72.302,403.632,74.18L403.632,74.18z"/>
								</g>
							</svg>
						</a>
					</li>
					<li class="last-item">
						<a href="mailto:callisezenwaka@outlook.com" title="Email">
							<!-- <?xml version="1.0" encoding="iso-8859-1"?> -->
							<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
							<svg class="social__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 14" style="enable-background:new 0 0 14 14;" xml:space="preserve">
								<g>
									<path d="M7,9L5.268,7.484l-4.952,4.245C0.496,11.896,0.739,12,1.007,12h11.986c0.267,0,0.509-0.104,0.688-0.271L8.732,7.484L7,9z"/>
									<path  d="M13.684,2.271C13.504,2.103,13.262,2,12.993,2H1.007C0.74,2,0.498,2.104,0.318,2.273L7,8L13.684,2.271z"/>
									<polygon  points="0,2.878 0,11.186 4.833,7.079 "/>
									<polygon  points="9.167,7.079 14,11.186 14,2.875 "/>
								</g>
							</svg>
						</a>
					</li>
				</ul>
			</div>
		</section>
		
		<!-- CONTACT -->
		<section class="contact__info">
			<div class="info">
				<div class="address">
					<h2 class="heading">Contact Address</h2>
					<ul class="list-unstyled">
						<li><span><i>Organization: </i></span><a  href="https://www.algoboard.com/">Algoboard</a></li>
						<li><span><i>Location: </i></span><a  href="#">Lagos, Nigeria.</a></li>
						<li><span><i>Phone: </i></span><a  href="tel:+23408032130560">+234-(803)-213-0560</a></li>
						<li><span><i>Email: </i></span><a  href="mailto:callisezenwaka@gmail.com">callisezenwaka@outlook.com</a></li>
					</ul>
				</div>
				<div class="form">
					<h2 class="heading" style="text-align: center;">Send an Email</h2>
					<div v-if="!!validations.length" class="validations">
						<ul style="text-align: left; list-style-type: disc;"><li style="list-style-type: disc;" v-for="(validation, index) in validations" :key="index">{{validation}}</li></ul>
					</div>
					<form action="">
						<div class="form--item">
							<label for="full_name" class="required">Full Name: </label>
							<input type="text" name="full_name" id="full_name" v-model="user.full_name" placeholder="John Doe" aria-required="true" aria-invalid="false" required>
						</div>
						<div class="form--item">
							<label for="email" class="required">Email: </label>
							<input type="email" name="email" id="email" v-model="user.email" placeholder="john.doe@mail.com" aria-required="true" aria-invalid="false" required>
						</div>
						<div class="form--item">
							<label for="phone" class="required">Phone number:</label>
							<input type="tel" name="phone" id="phone" v-model="user.phone" placeholder="+234 803 000 0000" aria-required="true" aria-invalid="false" required>
						</div>
						<div class="form--item">
							<label for="message" class="required">Message: </label>
							<textarea name="message" id="message" v-model="user.message" cols="30" rows="10"></textarea>
						</div>
						<div>
							<button type="button" class="btn" @click="onSubmit">Submit</button>
						</div>
					</form>
				</div>
			</div>
		</section>
	</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/partials/Header.vue'
import Footer from '@/components/partials/Footer.vue'
// import Spinner from '@/components/partials/Spinner.vue'
export default {
	name: 'Contact',
	components: {
		Header,
		Footer,
		// Spinner
	},
	data(){
		return {
			validations: [],
			user: {
				full_name: '',
				email: '',
				phone: '',
				message: '',
			}
		}
	},
	methods: {
		validate() {
			// Empty Errors container
			this.validations = []; 
			// Check if name field is empty
			if (!this.user.full_name) {
				this.validations.push("Full name is required!");
			}
			// Check if email field is empty
			if (!this.user.email) {
				this.validations.push("Email is required!");
			}
			// Check if phone field is empty
			if (!this.user.phone) {
				this.validations.push("Phone is required!");
			}
			// Check if message field is empty
			if (!this.user.message) {
				this.validations.push("Message is required!");
			}
			// Clear error messages on the page
			setTimeout(() => this.validations = [], 5000);
			// If No Errors Return True
			if (this.validations.length) {
				return false;
			}
			return true;
		},
		onSubmit() {console.log('here')
			let validate = this.validate();
			if (!validate) {
				return;
			}

		}
	}
}
</script>

<style scoped>
.contact {
	line-height: 2em;
	font-weight: 700;
	min-height: 100vh;
}
/* .contact__info {
	background-color: #FFFFFF;
} */
.info {
	display: flex;
	flex-direction: column;
	margin: 2rem 1rem;
	padding: 1rem;
	/* border: 1px solid #e2e2e2; */
	border-radius: 5px;
	font-size: 1rem;;
	font-weight: 700;
	background-color: #FFFFFF;
	box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}
.info:hover {
	border-radius: 5px;
	box-shadow: 0px 2px 5px #666;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in-out;
	box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}
.heading {
	margin: 1rem;
}
.social {
	display: inline-flex;
	flex-wrap:wrap;text-align: center;
}
.social__content {
	margin: 2rem 1rem;
	padding: 1rem;
	/* border: 1px solid #e2e2e2; */
	border-radius: 5px;
	font-weight: 700;
	text-align: center;
	background-color: #FFFFFF;
	box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}
.social__content:hover {
	border-radius: 5px;
	box-shadow: 0px 2px 5px #666;
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in-out;
	box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}
.social__icon {
	width: 2.5em;
	height: 2.5em;
	padding: 0;
	margin: 0.5em 1em;
}
.social__icon_color {
	fill:#AB3838;
}
.social__icon_dark_theme {
	fill:#15202b;
}
/* Address */
.address {
	margin-left: 4rem;
}
/* form */
.form {
	width: 100%;
	margin-top: 2rem;
}
.validations {
	display: flex;
	justify-content: center;
	/* background-color: #ff0000; */
	color: #ff0000;
	padding: .5rem;
	border-radius: 5px;
}
.required:after {
  color: #FF0000;
  content: "*";
  font-weight: 500;
  padding-left: 0.5rem;
}
.form--item {
	/* display: inline-block; */
	/* padding: 1rem; */
	margin: .5rem 0;
	border: 1px solid #ffffff;
	border-radius: 5px;
	width: 100%;
	position: relative;
	text-align: left;
}
/* input[type='text'] */
[type='text'],
[type='number'],
[type='date'],
[type='tel'],
[type='search'],
[type="email"],
[type='password'],
textarea {
	border: none;
	border-bottom: 2px solid #e2e2e2;
	background-color: #FFFFFF;
	font-size: inherit;
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transform: scale(1);
	transform: scale(1);
	transition: all 0.2s linear;
	width: 100%;
	outline: none;
	/* padding: 0 10px; */
}
[type='text']:focus,
[type='number']:focus,
[type='date']:focus,
[type='tel']:focus,
[type='search']:focus,
[type="email"]:focus,
[type='password']:focus,
textarea {
	border-bottom: 2px solid #f2e9d2;
	background-color: #FFFFFF;
	font-size: inherit;
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transform: scale(1);
	transform: scale(1);
	transition: all 0.2s linear;
}
.btn {
	border: 0.1rem solid #f2ecec;
	background-color: #f2e9d2;
	border-radius: 0.5rem;
	text-align: center;
	width: 100%;
	color: #000000;
	padding: 0.5rem 1rem;
	/* margin: 1rem; */
	cursor: pointer;
	outline: none;
	transition: all 1s ease-out;
}
.btn:hover {
	border: 0.1rem solid #f2e9d2;
	background-color: #f2ecec;
	color: #000000;
	transition: all 1s ease-in;
}
@media only screen and (min-width: 480px) {
.info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* padding: 2rem; */
}
/* form */
.form {
	width: 50%;
	margin-top: 0;
}
}
@media only screen and (min-width: 964px) {
.contact {
    padding: 4rem 20rem;
}
.info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* padding: 2rem; */
}
}
</style>
